import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

let appInsights: ApplicationInsights | undefined;
let reactPlugin: ReactPlugin | undefined;

if (typeof window !== 'undefined') {
  const browserHistory = createBrowserHistory();
  reactPlugin = new ReactPlugin();
  const connectionString =
    'InstrumentationKey=c792d82b-99c1-488b-95fe-0475d5d68db8;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=decddc53-ff1a-4686-b6a6-438a159c546e';
  appInsights = new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
  console.log('Application Insights initialized');

  // Add custom properties to telemetry data
  appInsights.addTelemetryInitializer((envelope) => {
    const telemetryItem = envelope.data.baseData;
    telemetryItem.properties = telemetryItem.properties || {};
    const userEmail = envelope.tags['ai.user.accountId']; // Assuming user email is set in accountId
    const loginTime = new Date().toISOString();
    if (userEmail) {
      telemetryItem.properties.userEmail = userEmail;
    }
    telemetryItem.properties.loginTime = loginTime;
  });
}

export { reactPlugin, appInsights };
