import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from 'react';
import { appInsights } from '../../configs/Login/appInsights'; // Import appInsights

export const Header = ({ title }) => {
  const getAccountDetails = useMsal();
  const currentAccountDetails = getAccountDetails.instance.getAllAccounts()[0];
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (getAccountDetails) {
      setUsername(currentAccountDetails?.name);
      // Log the email and login time in the console
      console.log('User Email:', currentAccountDetails?.username);
      const loginTime = new Date().toISOString();
      console.log('Login Time:', loginTime);

      // Track custom event for user login
      if (appInsights) {
        try {
          appInsights.trackEvent({
            name: 'UserLogin',
            properties: {
              userEmail: currentAccountDetails?.username,
              loginTime: loginTime,
            },
          });
          console.log('User login event tracked');

          // Flush telemetry data
          appInsights.flush();
          console.log('Telemetry data flushed');
        } catch (error) {
          console.error(
            'Error tracking user login event or flushing telemetry data:',
            error
          );
        }
      } else {
        console.error('Application Insights is not initialized');
      }
    }
  }, [getAccountDetails, currentAccountDetails]);

  const [hours] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning, ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon, ');
    } else if (hours >= 16) {
      setGreeting('Good evening, ');
    }
  }, [hours]);

  const currentDate = Date.now();

  const formatDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(currentDate);

  return (
    <React.Fragment>
      <div
        style={{
          background: 'rgb(42, 87, 117)',
          height: '10px',
          width: '100%',
        }}
      />
      <div
        className="landing-page-header-wrapper"
        date-testid="landing-page-header-wrapper"
      >
        <div
          style={{
            width: '20%',
            paddingRight: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              margin: '5px 5px 5px 20px',
            }}
          >
            <img src="/UHG-Logo.png" alt="UHG Logo" width={50} height={50} />
          </div>
          <div
            style={{
              width: '50%',
              marginRight: '20px',
            }}
          >
            <a
              href="https://uhgazure.sharepoint.com/:p:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/_layouts/15/Doc.aspx?sourcedoc=%7B554574CF-B5E7-4A9D-843B-ABBF887C6685%7D&file=E.R.I.C.%20Inventory%20Search%20Review.pptx&wdLOR=cFF4467D1-94EC-4550-A772-FD4CC1CD8D53&action=edit&mobileredirect=true"
              target="_blank"
              style={{ color: 'rgb(0, 38, 119)' }}
              rel="noreferrer"
            >
              <img
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                }}
                src="/access-guide-link.png"
                alt="access guide logo"
                width={50}
                height={50}
              />
            </a>
          </div>
        </div>
        <div
          style={{
            width: '85%',
            textAlign: 'center',
            marginRight: '5px',
            marginLeft: '5px',
            marginTop: '5px',
          }}
        >
          <h4>E.R.I.C. Inventory Search</h4>
        </div>
        <div
          style={{
            width: '5%',
            marginTop: '5px',
            paddingRight: '200px',
          }}
        >
          <img
            style={{
              position: 'absolute',
            }}
            src="/UHC-logo.png"
            alt="UHC Logo"
            width={100}
            height={50}
          />
        </div>
      </div>
      <div
        style={{
          paddingLeft: '20px',
          display: 'flex',
          marginTop: '5px',
          alignItems: 'center',
        }}
      >
        <span>
          <img src="/queries.png" alt="Queries icon" width={30} height={30} />
        </span>
        <br />
        <span
          style={{
            color: '#002677',
            fontWeight: '900',
            marginLeft: '5px',
            fontSize: '12px',
          }}
        >
          <a
            href="https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/USP%20Reporting%20%20Technology%20Intake%20Form/AllItems.aspx?viewpath=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FLists%2FUSP%20Reporting%20%20Technology%20Intake%20Form%2FAllItems%2Easpx"
            target="_blank"
            style={{ color: 'rgb(0, 38, 119)' }}
            rel="noreferrer"
          >
            Any Queries/comments, please write to us
          </a>
        </span>
      </div>
      <div
        style={{
          textAlign: 'left',
          paddingLeft: '20px',
        }}
      >
        <span
          style={{
            color: '#002677',
            fontWeight: '900',
            fontSize: '14px',
          }}
        >
          {`Hello ${username} - Welcome to USP Search`}
        </span>
        <span
          style={{
            float: 'right',
            fontWeight: '700',
            color: 'rgb(0, 38, 119)',
          }}
        >
          {formatDate}
        </span>
      </div>
      <div
        style={{
          marginTop: '8px',
          background: 'rgb(204 243 247)',
          height: '15px',
          width: '100%',
        }}
      />
      <title>{title} | E.R.I.C. Inventory Search </title>
    </React.Fragment>
  );
};
